/* Custom Fonts */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/InterVariable.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "League Spartan";
  src: url("./assets/fonts/LeagueSpartan.ttf") format("truetype");
  font-weight: 400;
}

/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  padding: 0 0px;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #141414 !important;
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

hr {
  /* Horizontal line settings for header! */
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 2s forwards;
  animation-delay: 1s;
  position: relative;
  top: -75px;
  margin-bottom: 20px;
  width: 35px;
}

@keyframes fadeIn {
  /* Animation for <hr>, using visibility to fulfill the effect */
  to {
    opacity: 1;
    visibility: visible;
  }
}

button {
  width: 100%;
  height: auto;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/* NavBar Css */

nav.navbar {
  padding-top: 0px;
  margin-top: -20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #141414;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #000;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 300;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 10px;
  font-size: 14px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #fff;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 52px;
  height: 52px;
  display: inline-flex;
  margin-right: -10px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.basescriptIcon {
  transform: scale(1.65);
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/

.banner {
  margin-right: 40px;
  padding: 20;
  background-color: #141414;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.banner .text-center {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  flex-direction: column; /* Stack elements vertically */
}

.banner img {
  vertical-align: middle;
  opacity: 0; /* Initially hidden */
  transform: translateY(-20px); /* Start position */
  animation: fadeInDown 1.5s forwards;
  animation-delay: 1.1s; /* Delayed slightly after the text */
  width: 100%;
  max-width: 600px;
  transition: all 0.3 ease;
  position: relative;
  top: 70px;
  right: 120px;
}

.banner h1,
.banner p {
  width: 100%; /* Ensure the text container takes up full width */
  text-align: center; /* Center text inside its container */
  max-width: 800px; /* Add a max-width to control how wide text goes */
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  font-family: Inter;
  letter-spacing: 0px;
  line-height: 1;
  margin-bottom: 0px;
  display: block;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 1.5s forwards;
  animation-delay: 0.7s;
}
@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.banner p {
  color: #fff;
  font-family: League Spartan;
  font-size: 18px;
  font-weight: 450;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 1.5s forwards;
  animation-delay: 0.9s; /* slightly longer delay than the h1 */
}

.banner p a {
  text-decoration: none;
  color: #eee4d2;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.animated-underline {
  display: inline-block;
  position: relative;
  color: #eee4d2;
}

.animated-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.animated-underline:hover {
  color: white; /* Change text color on hover */
  transition: color 0.5s ease;
}

.animated-underline:hover::after {
  transform: scaleX(1);
  height: 1px;
}

.animated-underline:not(:hover) {
  transition: color 0.5s ease; /* Ensure the color transition is smooth when not hovering */
}

.custom-button {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 150px;
  font-family: League Spartan;
  display: block;
  margin: 0 auto;
  text-align: center;
  border: none;
}

.fade-up {
  animation: fade-up 4s;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.stars-image {
  display: block;
  margin: 10px auto;
  text-align: center;
  border: none;
}

@media (max-width: 768px) {
  /* Adjust the max-width as necessary */
  .gif-container {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if needed */
    position: static; /* Reset position to static for mobile */
    margin-top: 20px; /* Adjust top margin for spacing */
  }

  .banner img {
    max-width: 100%; /* Make sure the image is responsive */
    height: auto; /* Maintain aspect ratio */
  }
}

/* About Section Css */

.about-section {
  background-color: #141414;
  color: #fff;
  padding: 150px 20px;
  text-align: right;
  padding-bottom: 150px;
}

.about-description .section-title {
  padding-left: 20px;
}

.section-title {
  font-size: 2.1rem;
  margin-bottom: 20px;
  color: #eee4d2;
  font-weight: 700;
  font-family: League Spartan;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  margin-left: 150px;
  font-family: League Spartan;
  text-align: right;
}

.highlight-link {
  color: #eee4d2;
  text-decoration: none;
}

.highlight-link:hover {
  text-decoration: underline;
}

.tech-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #eee4d2;
}

.tech-list li {
  position: relative;
  margin-bottom: 10px;
  text-align: right;
  font-family: Inter;
  font-style: oblique;
  font-weight: 200;
  font-size: 18px;
  color: #fff;
}

.tech-list li::before {
  content: "¤";
  position: absolute;
  left: 11rem;
}

.profile-image {
  max-width: 50% !important;
  border-radius: 25px;
  transition:
    transform 1.5s ease,
    filter 1.5s ease;
  margin-left: auto;
  margin-right: 20px;
}

.profile-image:hover {
  transform: translateX(-50px) rotate3d(0, 1, 0, -10deg); /* Moves left and tilts along the Y-axis */
  filter: drop-shadow(5px 5px #eee4d2); 
}


.fade-up-on-scroll {
  opacity: 0;
  transform: translateY(50px);
  transition:
    opacity 1.5s ease,
    transform 1.5s ease;
}

.fade-up-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

.about-description a {
  text-decoration: none;
  color: #eee4d2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Experience Css */

body {
  background: #3da3d5;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  color: black;
}

.title {
  font-size: 15em;
  text-align: center;
  font-family: "League Spartan", sans-serif;
  color: black;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow:
    0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
  transition: transform 0.5s ease;
}

.vertical-timeline-element-content:hover {
  transform: translateY(-10px);
}

.vertical-timeline-element-subtitle,
.vertical-timeline-element-title {
  color: black;
  font-family: League Spartan;
}

.date {
  font-family: League Spartan;
  color: black;
  display: block;
  margin-top: 5px;
}

#description {
  margin: 1.5em 0 2em 0;
  color: black;
  font-family: League Spartan;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: black;
}

.workButton:hover {
  background-color: #270222;
}

.schoolButton {
  background-color: black;
}

.schoolButton:hover {
  background-color: #270222;
}

.button-text {
  font-family: League Spartan;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}

/* Projects Css */

.projects h2 {
  text-align: right;
}

.projects-carousel {
  opacity: 0; 
  transform: translateY(50px); 
  transition: opacity 0.6s ease, transform 0.6s ease; 
  margin-top: 50px;
}

.projects-carousel.visible {
  opacity: 1; 
  transform: translateY(0); 
}

.embla {
  overflow: hidden;
  width: 95%;
  margin: 0 auto;
}

.embla__container {
  display: flex;
}

.embla__slide {
  position: relative;
  min-width: 30%;
  padding: 1rem;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.project-image {
  transition: transform 1.5s ease, filter 1.5s ease;
}

.project-image:hover {
  transform: scale(0.98); 
  filter: hue-rotate(-30deg); 
}


.project-image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  object-fit: cover;
}

.project-title {
  text-align: center;
  font-family: League Spartan;
  font-weight: 500;
  margin-bottom: 5px;
}

.project-desc {
  text-align: center;
  font-family: League Spartan;
  margin-bottom: 5px;
}

.project-stacks {
  font-size: 0.8rem;
  font-style: italic;
  color: #eee4d2;
}

.section-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.projects-button {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100px;
  font-family: League Spartan;
  margin: 0 auto;
  text-align: center;
  border: none;
  justify-content: center;
  margin: 5px;
}

/* Footer CSS */

.footer {
  font-family: League Spartan;
  text-align: center;
  font-weight: 100;
  font-size: 0.9rem;
  padding-top: 50px;
  line-height: 1.2;
}

/* Mobile CSS Optimization */

@media (max-width: 768px) {
  body {
    padding: 0 10px; /* Slight padding for mobile devices */
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 12px; /* Smaller font size for mobile */
    padding: 0 5px; /* Reduced padding */
    display: block;
  }

  .logo {
    margin-top: 20px;
    width: auto;
    height: auto; /* Smaller logo size for mobile */
    transform: scale(1.5);
  }

  .banner h1 {
    font-size: 40px; /* Smaller heading size for mobile */
    text-align: center;
  }

  .banner img {
    width: 58%;
    position: relative;
    right: 0px;
    top: 0px;
    margin-bottom: -100px;
  }

  .banner p {
    font-size: 16px; /* Smaller paragraph size for mobile */
    width: 90%; /* Slightly reduce width on mobile */
    display: inline-block;
    text-align: center;
  }

  .banner button {
    font-size: 16px; /* Adjust button size for mobile */
    margin-top: 30px; /* Reduced margin */
  }

  .navbar-text button {
    font-size: 14px; /* Smaller button text */
  }

  .about-section,
  .about-description {
    text-align: center;
    display: block;
    margin: 0;
  }

  .tech-list li::before {
    content: "◦";
    position: absolute;
    left: 0rem;
  }

  .tech-list li {
    text-align: center;
  }

  /* Ensure images are responsive */
  img {
    max-width: 100%; /* Prevent images from overflowing */
    height: auto; /* Maintain aspect ratio */
  }

  /* Flexbox adjustments for main content */
  main .container {
    padding: 0 10px; /* Adjust padding for mobile */
    flex-direction: column; /* Stack items vertically */
  }
}

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}
